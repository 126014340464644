import React, { useState } from 'react';
import { Navbar, Nav, NavbarBrand, NavbarToggler, NavItem, NavbarText, DropdownMenu, 
	Collapse, DropdownItem, Container,
	Card, CardHeader, CardBody,
	Form, Row, Col, FormGroup, Input, Button } from 'reactstrap';

const Header = (props) => {

	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	let isLoggedIn = false;

	return (
		<Navbar color="dark" dark xs={3}>
			<NavbarBrand href="https://thorkildsen.com/" className="m-1">Thorkildsen.com</NavbarBrand>
		</Navbar>
	);
/*
	return (
		<Navbar color="dark" dark xs={3}>
			<NavbarBrand href="/" className="m-1">[T]</NavbarBrand>
			<Nav className="mr-auto" navbar>
				<NavItem><TagsBar language={props.language} /></NavItem>
			</Nav>
			<NavbarToggler onClick={toggle} />

			<Collapse isOpen={isOpen} navbar>
				<Nav right navBar style={{textAlign:'right'}}>
					<NavItem>{ isLoggedIn ? <UserMenu /> : <Login language={props.language} /> }</NavItem>
				</Nav>
			</Collapse>
		</Navbar>
	);
*/
}

const Front = (props) => {

	const texts = {
		nb: {
			header: 'Hm..',
			text: 'En CV blir så tom uten en person tilkoblet.'
		},
		en: {
			header: 'Hm..',
			text: 'A resumé seems so empty with anyone attached.'
		}
	};
	let lang = props.language;
	if (!texts[lang]) lang = 'en';

	return (
		<Row><Col sm="12" md={{ size:4, offset:4 }}>
			<Card className="m-3">
				<CardHeader>{texts[lang].header}</CardHeader>
				<CardBody>{texts[lang].text}</CardBody>
			</Card>
		</Col></Row>
	);
};

const TagsBar = (props) => {

	const langText = {
		'nb': 'Søk...',
		'en': 'Search...'
	};
	let txtField = langText[props.language];

	return (
		<Container>
			<Row>
				<Col m={1}>
					<Input type="text" placeholder={txtField} />
				</Col>
			</Row>
		</Container>
	);
};

const UserMenu = (props) => {
	return (
		<div />
	);
}

const Login = (props) => {

	const langText = {
		'nb': {
			'email': 'E-post',
			'password': 'Passord',
			'button': 'Logg inn'
		},
		'en': {
			'email': 'E-mail',
			'password': 'Password',
			'button': 'Sign in'
		}
	};
	const txtEmail = langText[props.language]['email'];
	const txtPassword = langText[props.language]['password'];
	const txtButton = langText[props.language]['button'];

	return (
		<Container>
		<Form>
			<Row xs={3}>
				<Col>
					<Input type="email"
						name="email" id="loginUsername" 
						placeholder={txtEmail} />
				</Col>
				<Col>
					<Input type="password" name="password" 
						id="loginPassword" 
						placeholder={txtPassword} />
				</Col>
				<Col>
					<Button>{txtButton}</Button>
				</Col>
			</Row>
		</Form>
		</Container>
	);
};

const Footer = (props) => {
	return (
		<div className="footer">
			<p>Copyright &copy; 2021</p>
		</div>
	);
}

export { Header, Footer, Front };

