import './App.css';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';

import { Header, Footer, Front } from './components/Base';
import Cv from './components/Cv';

function App() {

	document.title = 'CV : Thorkildsen.com';
	const data = {
		'api': '',
		'url': document.location.href,
		'language': 'nb'
	};

	return (
		<div className="App">
			<Header language={data.language} />
			<Router><Switch>
				<Route exact path="/" component={() => <Front language={data.language} />} /> 
				<Route component={() => <Cv language={data.language} />} />
			</Switch></Router>
			<Footer language={data.language} />
		</div>
	);
}

export default App;
