import { React } from 'react';
import { useState, useEffect } from 'react';
import { Container, Row, Col,
		Card, CardHeader, CardTitle, CardBody,
		Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
		Button, ButtonGroup, Collapse, Spinner } from 'reactstrap';

import { FontAwesome } from 'react-fontawesome';
import faStyles from 'font-awesome/css/font-awesome.css' 
import { Link } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(fab, fas);


const Cv = (props) => {

	const [ cv, setCv ] = useState({
		'isLoaded': false,
		'wontLoad': false,
		'data': {}
	})

	useEffect(() => {
		const aFetchCv = async () => {

			let urlParts = document.location.href.split('/');
			let profileName = '';
			if (urlParts[3]) {
				profileName = urlParts[3];

				fetch('//thorkildsen.com/api/nb/cv/'+profileName)
					.then((res) => res.json())
					.then((d) => {

						var tags = [];
						if (d.positions) {
							tags = tags.concat(d.positions.map(e => { return (e.tags) ? e.tags : [] } ));
						}
						if (d.education) {
							tags = tags.concat(d.education.map(e => { return (e.tags) ? e.tags : [] } ));
						}
						if (d.certificates) {
							tags = tags.concat(d.certificates.map(e => { return (e.tags) ? e.tags : [] } ));
						}
						if (d.projects) {
							tags = tags.concat(d.projects.map(e => { return (e.tags) ? e.tags : [] } ));
						}
						tags = tags.filter(e => e.length > 0);
						
						d.tags = [].concat(...tags);
						d.tags = d.tags.map(e => e.toLowerCase())
						.filter((t,i,a) => {
							return i === a.indexOf(t);
						});

						setCv({isLoaded: true, wontLoad: false, data: d });
					})
					.catch((err) => {
						setCv({isLoaded: false, wontLoad: true, data: err });
					});

			} else {

				setCv({wontLoad: true});
			}
			
		};
		aFetchCv();
	}, []);


	const title = {
		'edu': props.language === 'nb' ? 'Utdanning' : 'Education',
		'pos': props.language === 'nb' ? 'Erfaring'  : 'Experience',
		'cert':props.language === 'nb' ? 'Kurs og sertifiseringer': 'Courses and ceritfications',
		'pro': props.language === 'nb' ? 'Prosjekter': 'Projects'
	};

	if (cv.wontLoad) {
		return (
			<Row><Col sm="12" md={{ size:4, offset:4 }}>
				<Card className="m-3">
					<CardHeader>Error</CardHeader>
					<CardBody>Invalid or no profile specified.</CardBody>
				</Card>
			</Col></Row>
		);
	} else if (!cv.isLoaded) {
		return (
			<Row><Col sm="12" md={{ size:4, offset:4 }} style={{ textAlign: 'center' }}>
				<div className="m-3">
				<Spinner color="green"> </Spinner>
				</div>
			</Col></Row>
		);
	} else {

		document.title = 'CV : '+cv.data.name;

		return (
			<Row><Col sm="12" md={{ size:6, offset:3}}>
				<Personalia name={cv.data.name} personal={cv.data.personal}
					contact={cv.data.contact}
					language={props.language}
					tags={cv.data.tags}
				/>
				<CvList key="eduList" title={title.edu} items={cv.data.education} />
				<CvList key="posList" title={title.pos} items={cv.data.positions} />
				<CvList key="certList" title={title.cert} items={cv.data.certificates} />
				<CvList key="proList" title={title.pro} items={cv.data.projects} />
			</Col></Row>
		);
	}
};


const CvList = (props) => {

	const [ selectedTags, setTags ] = useState({tags:[]});

	let uniqueTags = ([].concat(...([].concat(props.items.map(i => i.tags)))))
		.map(i => i.toLowerCase())
		.filter((e,i,a) => { 
			return (a.indexOf(e) === i) && (e.length > 0);
		})
		.sort();


	// set open/closed state
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);


	const items = props.items.map((e) => {

		let startEnd = e.startDate.substring(0,7) + ' - ' + (e.endDate && e.endDate.length>0 ? e.endDate.substring(0,7) : '');
		var itemStyle = { borderTop: '1px dotted gray' };

		if (selectedTags.tags.length > 0 
				&& ( e.tags.length === 0 || (e.tags.map(i => i.toLowerCase()).filter(i => selectedTags.tags.indexOf(i) >= 0)).length === 0)) {
			itemStyle.display = 'none';
		}

		// filtering-function
		const f = (t) => {
			if (typeof(t) === 'string') {
				return <span>{t} </span>;
			} else if (!t.link && t.text) {
				return (
					<span>{t.text} </span>
				);
			} else {
				return (
					<span><a href={t.link}>{t.text}</a> </span>
				);
			}
		};
		const tit = e.title.map(f);
		const org =   e.org.map(f);

		const links = (e.links && e.links.length > 0) ? e.links : [];

		return (
			<div className="mt-1 mb-2" style={itemStyle}>
			<Row className="mt-3">
				<Col tag="h5" sm="12" md={{ size:3 }} style={{textAlign:'left'}}>{startEnd}</Col>
				<Col tag="h5" sm="12" md={{ size:9 }} style={{textAlign:'left'}}>{tit}</Col>
			</Row>
			<Row>
				<Col sm="12" md={{ offset:3, size:9 }} style={{textAlign:'left'}}>{org}</Col>
			</Row>
			<Row>
				<Col sm="12" md={{ offset:3, size:9 }} style={{textAlign:'left'}}>
					<div dangerouslySetInnerHTML={{__html: e.description}} />
				</Col>
				<Col sm="12" md={{ offset:3, size:9 }} style={{textAlign:'left'}}>
					<CvListItemLinks items={links} />
				</Col>
				<Col sm="12" md={{ offset:3, size:9 }} style={{textAlign:'right'}}>
					<Tagsbar tags={e.tags} selectedTags={selectedTags.tags} setTags={setTags} />
				</Col>
			</Row>
			</div>
		);
	});

	const filtering = (uniqueTags.length === 0) ? '' :(<span>
		Filter: <Tagsbar tags={uniqueTags} selectedTags={selectedTags.tags} setTags={setTags}/>
	</span>);

	return (
		<Card className="mt-3">
			<CardHeader onClick={toggle} style={{ cursor:'pointer' }}>
				<Row><Col sm="12" md={{ size:10 }}>
					{props.title} ({props.items.length})
				</Col><Col sm="12" md={{ size:2 }} style={{ textAlign: 'right' }}>
					<FontAwesomeIcon icon={isOpen ? ['fas','angle-up'] : ['fas','angle-down']} />
				</Col></Row>
			</CardHeader>
			<Collapse isOpen={isOpen}>
				<CardBody>{filtering}{items}</CardBody>
			</Collapse>
		</Card>
	);
}; 

const CvListItemLinks = (props) => {

	const links = props.items.map(e => {
		if (!(e.link && e.text && e.icon)) {
			return '';
		}
		return (
			<a href={e.link} className="btn"><FontAwesomeIcon icon={e.icon} /> {e.text}</a>
		);
	});

	return links;
};


const Tagsbar = (props) => {

	const toggleTag = (ev) => {
		var tagName = ev.target.innerHTML.toLowerCase();
		var curSelected = props.selectedTags;
		
		if (props.selectedTags && props.selectedTags.length > 0 && props.selectedTags.indexOf(tagName) >= 0) {
			curSelected = curSelected.filter(t => t !== tagName);
		} else {
			if (curSelected && curSelected.length > 0) {
				curSelected.push(tagName);
			} else {
				curSelected = [tagName];
			}
		}
		props.setTags({tags: curSelected});
	};
	
	const tags = !props.tags ? '' : props.tags.map(t => {
		if (props.selectedTags && props.selectedTags.indexOf(t.toLowerCase()) >= 0) {
			return (
				<Button size="sm" color="success" className="m-1" onClick={toggleTag}>{t}</Button>
			);
		}
		return (
			<Button size="sm" outline color="secondary" className="m-1" onClick={toggleTag}>{t}</Button>
		);
	});

	return (
		<>{tags}</>
	);
};

const Personalia = (props) => {

console.debug('contact',props.contact);
	const contact = props.contact.map((p) => {
		let hasIcon = false, icon = '';
		if (p.icon && p.iconGroup) {
			hasIcon = true;
			icon = [p.iconGroup, p.icon];
		}
		return (
			<Button size="sm" color="secondary" href={p.link} target="_blank" style={{ whiteSpace: 'nowrap' }}>
				{ hasIcon ? <FontAwesomeIcon icon={icon} /> : '' } {p.text}
			</Button>
		);
	});

	const allTags = props.tags.map(t => {
			return <Button link color="secondary">{t}</Button>
		});

	const [filtersOpen, setFiltersOpen] = useState(false);
	const toggleFilters = () => setFiltersOpen(prevState => !prevState);

	const filterMenu = (
		<Dropdown isOpen={filtersOpen} toggle={toggleFilters}>
			<DropdownToggle size="sm" caret>Filter{ props.language==='nb' ? 'e' : 's' }</DropdownToggle>
			<DropdownMenu><DropdownItem><Container>{allTags}</Container></DropdownItem></DropdownMenu>
		</Dropdown>
	);

	const persInfo = props.personal.map(p => {
		return (
			<>
			<Col sm="12" md={{ size:3 }}>{p.field}</Col>
			<Col sm="12" md={{ size:3 }}>{p.value}</Col>
			</>
		);
	});

	return (
		<div key="persInfo">
		<h1 className="mt-4">CV: {props.name}</h1>
		<Container style={{ borderTop: '1px dotted gray' }}>
			<Row className="mt-3">
				{persInfo}
				<Col sm="12" md={{ size:9 }}>
					<br />
					<ButtonGroup>{contact}</ButtonGroup> 
				</Col>
				<Col sm="12" md={{ size:3 }} style={{ textAlign:'right', display:'none' }}>
					<br />
					{filterMenu}
				</Col>
			</Row>
		</Container>
		</div>
	);
};

export default Cv;

